import CabinSelectAWX from "@componentsAWX/pages/CabinSelect";
import CruiseAWX from "@componentsAWX/pages/Cruise";
import PassengersAWX from "@componentsAWX/pages/Passengers";
import PaymentAWX from "@componentsAWX/pages/Payment";
import PaymentConfirmationAWX from "@componentsAWX/pages/PaymentConfirmation";
import RoomsAWX from "@componentsAWX/pages/Rooms";
import SearchAWX from "@componentsAWX/pages/Search";
import SearchResultsAWX from "@componentsAWX/pages/SearchResults";
import CabinSelectCRT from "@componentsCRT/pages/CabinSelect";
import CruiseCRT from "@componentsCRT/pages/Cruise";
import PassengersCRT from "@componentsCRT/pages/Passengers";
import PaymentCRT from "@componentsCRT/pages/Payment";
import PaymentConfirmationCRT from "@componentsCRT/pages/PaymentConfirmation";
import RoomsCRT from "@componentsCRT/pages/Rooms";
import SearchCRT from "@componentsCRT/pages/Search";
import SearchResultsCRT from "@componentsCRT/pages/SearchResults";
import CabinSelectV1 from "@componentsV1/pages/CabinSelect";
import CruiseV1 from "@componentsV1/pages/Cruise";
import PassengersV1 from "@componentsV1/pages/Passengers";
import PaymentV1Airwallex from "@componentsV1/pages/Payment/payment-providers/airwallex";
import PaymentV1 from "@componentsV1/pages/Payment/payment-providers/authorize";
import PaymentConfirmationV1 from "@componentsV1/pages/PaymentConfirmation";
import RoomsV1 from "@componentsV1/pages/Rooms";
import SearchV1 from "@componentsV1/pages/Search";
import SearchResultsV1 from "@componentsV1/pages/SearchResults";
import {
  RouteElement,
  ROOT,
  SEARCH_RESULTS,
  CRUISE,
  ROOMS,
  CABINS_SELECT,
  PASSENGERS,
  PAYMENT,
  PAYMENT_CONFIRMATION,
  UIVersion,
  PaymentProvider,
} from "@utils/constants/routes";
import i18n from "@utils/i18n";

const UI_VERSION = process.env.REACT_APP_UI_VERSION as UIVersion | undefined;

const PAYMENT_PROVIDER = process.env.REACT_APP_CABIN_SELECT_PAYMENT_PROVIDER as
  | PaymentProvider
  | undefined;

const ELEMENTS_BY_VERSION: Record<UIVersion, Record<string, React.FC<React.PropsWithChildren>>> = {
  v1: {
    search: SearchV1,
    searchResults: SearchResultsV1,
    cruise: CruiseV1,
    rooms: RoomsV1,
    cabinSelect: CabinSelectV1,
    passengers: PassengersV1,
    payment:
      PAYMENT_PROVIDER === "authorize"
        ? PaymentV1
        : PAYMENT_PROVIDER === "airwallex"
          ? PaymentV1Airwallex
          : PaymentV1,
    paymentConfirmation: PaymentConfirmationV1,
  },
  crt: {
    search: SearchCRT,
    searchResults: SearchResultsCRT,
    cruise: CruiseCRT,
    rooms: RoomsCRT,
    cabinSelect: CabinSelectCRT,
    passengers: PassengersCRT,
    payment: PaymentCRT,
    paymentConfirmation: PaymentConfirmationCRT,
  },
  awx: {
    search: SearchAWX,
    searchResults: SearchResultsAWX,
    cruise: CruiseAWX,
    rooms: RoomsAWX,
    cabinSelect: CabinSelectAWX,
    passengers: PassengersAWX,
    payment: PaymentAWX,
    paymentConfirmation: PaymentConfirmationAWX,
  },
};

const PRIVATE_ROUTES: RouteElement = {
  [ROOT]: {
    element: ELEMENTS_BY_VERSION[UI_VERSION || "v1"].search,
    layout: "auth",
    uiVersion: "v1",
    title: i18n.t("search"),
  },
  [SEARCH_RESULTS]: {
    element: ELEMENTS_BY_VERSION[UI_VERSION || "v1"].searchResults,
    layout: "search",
    title: i18n.t("search results"),
    uiVersion: "v1",
    crumbParams: ["start_date", "end_date"],
  },
  [CRUISE]: {
    element: ELEMENTS_BY_VERSION[UI_VERSION || "v1"].cruise,
    layout: "search",
    title: i18n.t("number of staterooms"),
    uiVersion: UI_VERSION || "v1",
    crumbParams: ["start_date", "end_date", "source"],
  },
  [ROOMS]: {
    element: ELEMENTS_BY_VERSION[UI_VERSION || "v1"].rooms,
    layout: "search",
    title: i18n.t("staterooms"),
    uiVersion: "v1",
    crumbParams: [
      "start_date",
      "end_date",
      "rooms",
      "room",
      "fare_1",
      "grade_1",
      "guests_1",
      "market_1",
      "source",
    ],
  },
  [CABINS_SELECT]: {
    element: ELEMENTS_BY_VERSION[UI_VERSION || "v1"].cabinSelect,
    layout: "search",
    title: i18n.t("staterooms"),
    uiVersion: "v1",
    crumbParams: [
      "start_date",
      "end_date",
      "rooms",
      "room",
      "fare_*",
      "grade_*",
      "guests_*",
      "market_*",
      "cabin_*",
      "deck_*",
      "source",
    ],
  },
  [PASSENGERS]: {
    element: ELEMENTS_BY_VERSION[UI_VERSION || "v1"].passengers,
    layout: "search",
    title: i18n.t("guests"),
    uiVersion: "v1",
    crumbParams: [
      "start_date",
      "end_date",
      "rooms",
      "room",
      "fare_*",
      "grade_*",
      "guests_*",
      "market_*",
      "cabin_*",
      "deck_*",
      "source",
    ],
  },
  [PAYMENT]: {
    element: ELEMENTS_BY_VERSION[UI_VERSION || "v1"].payment,
    layout: "search",
    uiVersion: "v1",
    title: i18n.t("payment"),
  },
  [PAYMENT_CONFIRMATION]: {
    element: ELEMENTS_BY_VERSION[UI_VERSION || "v1"].paymentConfirmation,
    layout: "search",
    uiVersion: "v1",
    title: i18n.t("payment_confirmation"),
  },
};

export default PRIVATE_ROUTES;
