import { TabProps, ComponentsOverrides, Theme } from "@mui/material";

declare module "@mui/material/Tab" {
  interface TabPropsColorOverrides {
    "bg-primary": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
    red: true;
  }
}

interface IMuiTab {
  defaultProps: Partial<TabProps>;
  styleOverrides: ComponentsOverrides<Theme>["MuiTab"];
}

export const MuiTab: IMuiTab = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.toPx(theme.customShape.largeRadius),
      border: "2px solid",
      borderColor: theme.palette["bg-secondary"].main,
      minHeight: 110,
      color: theme.palette["text-primary"].main,
      textTransform: "none",
      transition: theme.transitions.create(["background-color", "color"], {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
      [theme.breakpoints.down("md")]: {
        minHeight: 42,
        padding: theme.spacing(1, 2),
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette["bg-secondary"].main,
        color: theme.palette["text-light"].main,
      },
      "&:hover": {
        backgroundColor: theme.palette["bg-secondary"].main,
        color: theme.palette["text-light"].main,
      },
    }),
  },
};
